<template>
  <div>
    <d-body-top-bar
      :title="$t('views.client.customer-details')"
      :class="$store.getters['layout/getInnerWidth'] < 1332 ? 'mt-3' : ''"
    >
      <div slot="tree">
        <label class="inactive-tree">
          {{ $t('views.client.your-customers') }} /
          <label
            class="inactive-tree link-area pointer"
            @click="$router.push({ name: 'clients_list'})"
          >
            {{ $t('views.client.all-customers') }}
          </label> /
        </label>
        <label
          class="bg-doinsport-primary ml-1"

        >
          {{ $t('views.client.customer-details') }}
        </label>
      </div>
    </d-body-top-bar>
    <div
      v-if="client"
      class="container-fluid"
    >
      <b-row no-gutters>
        <b-col :cols="mobileCol (3)">
          <Infos
            :client="client"
            :subscription-cards="subscriptionCards"
            @on:reload-client="loadClient"
          />
        </b-col>
        <b-col :cols="mobileCol (9)">
          <activities
            :client="client"
            :classes="mobileMargins()"
          />
          <b-row
            no-gutters
          >
            <b-col :cols="mobileCol (8)">
              <left-body-layout
                :client="client"
                :classes="mobileMargins()"
                :subscription-cards="subscriptionCards"
                @on:subscription-card-update="loadSubscriptionCards"
              />
            </b-col>
            <b-col :cols="mobileCol (4)">
              <right-body-layout
                :classes="rightBodyMargins()"
                :client="client"
              />
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import {getSubscriptionCardsClient} from "@api/doinsport/services/subscription-card/subscription-card.api";
import {getClubClient} from "@api/doinsport/services/client/client.api";
import Client from "@/classes/doinsport/Client";

export default {
  components: {
    Infos: () => import('@views/client/details/infos/Index'),
    Activities: () => import('@views/client/details/activities/Index'),
    LeftBodyLayout: () => import('@views/client/details/body/left/Index'),
    RightBodyLayout: () => import('@views/client/details/body/right/Index')
  },
  data: () => ({
    innerWidth: 1600,
    subscriptionCards: null,
    client: null,
  }),
  computed: {},
  methods: {
    mobileCol(x) {
      return this.innerWidth <= 750 ? '12' : x;
    },
    mobileMargins() {
      return this.innerWidth <= 991 ? 'ml-0' : 'ml-4';
    },
    rightBodyMargins() {
      return this.innerWidth <= 991 ? 'ml-0' : 'ml-3';
    },
    handleResize() {
      this.innerWidth = window.innerWidth;
    },
    loadClient() {
      getClubClient(this.$route.params.id)
        .then((response) => {
          this.client = new Client(response.data, {serialize: true});
          this.client.rib = response.data.rib;
        })
      ;
    },
    notExpired(paymentToken) {
      if (paymentToken.expiresAt) {
        return this.$moment(paymentToken.expiresAt).isAfter(this.$moment());
      }
      return false;
    },
    status(subscriptionCard) {
      if (subscriptionCard.canceled) {
        return 'canceled'
      } else if (this.$moment.utc(subscriptionCard.startDate).isAfter(this.$moment.utc())) {
        return 'programmed'
      }

      const diffNowEndDateAsDay = this.$moment.utc(subscriptionCard.endDate).diff(this.$moment.utc(), 'days');

      if (diffNowEndDateAsDay > 30) {
        return 'progress';
      } else if (diffNowEndDateAsDay <= 30 && diffNowEndDateAsDay > 0) {
        return diffNowEndDateAsDay;
      } else if (diffNowEndDateAsDay <= 0) {
        const diffNowEndDateAsHour = this.$moment.utc(subscriptionCard.endDate).diff(this.$moment.utc(), 'hours');

        if (diffNowEndDateAsHour <= 0) {
          return 'expired';
        } else {
          return 'expire-in-couple-hours';
        }
      }
    },
    loadSubscriptionCards() {
      getSubscriptionCardsClient(this.$route.params.id)
        .then((response) => {
            this.subscriptionCards = [];
            let isDescending = true;
            let cards = [];

            for (const subscriptionCard of response.data['hydra:member']) {
              if (this.status(subscriptionCard) !== 'canceled') {
                cards.push(subscriptionCard);
              }
            }

            this.subscriptionCards = cards.sort((a, b) => {
              return isDescending ?
                new Date(b.endDate).getTime() - new Date(a.endDate).getTime() :
                new Date(a.endDate).getTime() - new Date(b.endDate).getTime()
                ;
            });

            for (const subscriptionCard of response.data['hydra:member']) {
              if (this.status(subscriptionCard) === 'canceled') {
                this.subscriptionCards.push(subscriptionCard);
              }
            }
          }
        )
      ;
    }
  },
  created() {
    this.innerWidth = window.innerWidth;
    this.loadSubscriptionCards();
    this.loadClient();
  },
  mounted() {
    window.addEventListener('resize', this.handleResize);
  }
}
</script>

<style lang="scss" scoped>
.smaller-font {
  font-size: 14px;
}
</style>
